import { ChevronRight } from 'lucide-react';
import Link from 'next/link';
import cn from 'classnames';

type Props = {
  title: string;
  href: string;
  style?: 'primary' | 'secondary';
  arrow?: boolean;
  small?: boolean;
};

export default function LandingButton({
  title,
  href,
  style = 'primary',
  arrow = true,
  small = false
}: Props) {
  return (
    <Link href={href} legacyBehavior={true}>
      <a
        className={cn(
          ' rounded-full px-5  items-center text-sm font-medium transition whitespace-nowrap  ',
          {
            'dark:bg-white bg-black text-white dark:text-black hover:bg-neutral-800 hover:dark:bg-neutral-300':
              style === 'primary',
            'bg-transparent hover:bg-line opacity-90 hover:opacity-100':
              style === 'secondary',
            'py-2': small,
            'py-3': !small
          }
        )}
      >
        {title}
        {arrow && (
          <ChevronRight
            size={15}
            strokeWidth={2}
            className={cn(
              'ml-0.5 -mt-0.5 text-white dark:text-black inline-block',
              {
                'text-black dark:text-white': style === 'secondary'
              }
            )}
          />
        )}
      </a>
    </Link>
  );
}
