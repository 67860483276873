import { Moon, Sun } from 'lucide-react';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

type ToggleButtonProps = {
  iconClassName?: string;
  className?: string;
};
const ToggleButton = ({
  className = 'nav-button',
  iconClassName
}: ToggleButtonProps) => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  function disableTransitionsTemporarily() {
    document.documentElement.classList.add('[&_*]:!transition-none');
    window.setTimeout(() => {
      document.documentElement.classList.remove('[&_*]:!transition-none');
    }, 0);
  }

  useEffect(() => setMounted(true), []);
  if (!mounted) return null;

  const toggleMode = () => {
    disableTransitionsTemporarily();
    if (theme == 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  };

  return (
    <div className={className}>
      <button className="" onClick={toggleMode}>
        {theme == 'dark' ? (
          <Sun name="sun" className={classNames('h-4 w-4 ', iconClassName)} />
        ) : (
          <Moon name="moon" className={classNames('h-4 w-4 ', iconClassName)} />
        )}
      </button>
    </div>
  );
};

export default ToggleButton;
