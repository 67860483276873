// import Link from 'next/link';
// import s from './Footer.module.css';

import Logo from '@/components/icons/Logo';
import { Linkedin, X } from 'lucide-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useUiStore } from '@/store/ui';

// import Logo from '@/components/icons/Logo';
// import GitHub from '@/components/icons/GitHub';

const navigation = {
  page: [
    { name: 'Home', href: '/' },
    { name: 'Platform', href: '/' },
    // { name: 'Singals', href: '/l/signals' },
    { name: 'Dashboard', href: '/home' },
    { name: 'API', href: '/l/api' }
  ],
  support: [
    { name: 'Contact', href: '/contact' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'API Status', href: 'https://status.markets.sh', external: true },
    { name: 'API Docs', href: '/docs/api' },
    { name: 'Submit to index', href: '/lists/requests' }
  ],
  company: [
    { name: 'About', href: '/about' }
    // { name: 'Blog', href: '/blog' }
    // { name: 'Jobs', href: '/jobs' }
    // { name: 'Press', href: '/signin' },
    // { name: 'Partners', href: '/signin' }
  ],
  legal: [
    { name: 'Legal', href: '/legal' },
    { name: 'Privacy', href: '/privacy' },
    { name: 'Imprint', href: '/imprint' }
  ],
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/markets-sh/',
      icon: Linkedin
    },

    {
      name: '𝕏',
      href: 'https://x.com/markets_sh',
      icon: X
    }
    // {
    //   name: 'App Store',
    //   href: 'Google',
    //   icon:
    // }
  ]
} as {
  [key: string]: {
    name: string;
    href: string;
    icon?: (props: any) => JSX.Element;
    external?: boolean;
  }[];
};

export default function Footer() {
  const router = useRouter();
  const ui = useUiStore();
  if (router.pathname === '/chat') {
    return null;
  }
  return (
    <footer
      className={classNames(
        'relative bg-gradient-to-b from-neutral-100 to-neutral-200 dark:from-black dark:to-black py-10 z-20  ',
        {
          'pl-52': ui.sidebar_pinned,
          'pl-14': !ui.sidebar_pinned
        }
      )}
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold  tracking-wider uppercase">
                  Page
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.page.map((item) => (
                    <li key={item.name}>
                      <Link legacyBehavior href={item.href}>
                        <a className="text-sm ">{item.name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold  tracking-wider uppercase">
                  Support
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <Link legacyBehavior href={item.href}>
                        <a className="text-sm ">{item.name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold  tracking-wider uppercase">
                  Company
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <Link legacyBehavior href={item.href}>
                        <a className="text-sm ">{item.name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold  tracking-wider uppercase">
                  Legal
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link legacyBehavior href={item.href}>
                        <a className="text-sm ">{item.name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0 flex justify-start md:justify-end items-start">
            <Logo width={60} />
          </div>
          {/* <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold  tracking-wider uppercase">
              Subscribe to our newsletter
            </h3>
            <p className="mt-4 text-sm text-gray-200">
              The latest news, articles, and resources, sent to your inbox
              weekly.
            </p>
            <form className="mt-4 sm:flex sm:max-w-md">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="w-full bg-blue-700 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-500"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div> */}
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target={item.external ? '_blank' : '_self'}
                className=" hover:text-gray-300"
                rel="noreferrer"
              >
                <span className="sr-only">{item.name}</span>
                {item.icon && (
                  <item.icon
                    className="h-6 w-6 stroke-neutral-800 dark:stroke-neutral-400"
                    aria-hidden="true"
                  />
                )}
              </a>
            ))}
          </div>
          <div>
            {/* <a
              href="https://apps.apple.com/de/app/markets-sh/id6473627270"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={AppStore} alt={'Download in the app store'} />
            </a> */}
          </div>
        </div>
        <div className="mt-5">
          <p className=" text-xs opacity-80  md:mt-0 md:order-1">
            &copy; 2024 markets.sh GmbH All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
